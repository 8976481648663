<!-- HtmlRenderer.vue -->
<template>
  <div v-html="htmlContent"></div>
</template>

<script>
export default {
  name: 'HtmlRenderer',
  props: {
    htmlContent: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
</style>
