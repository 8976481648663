<template>
  <div>
    <div v-if="!showModalEspera">
      <div v-if="errors.length !== 0">
        <div class="d-flex" v-for="error in errors">
          <feather-icon icon="AlertTriangleIcon" size="20" class="wr-icon"></feather-icon>
          <html-renderer :htmlContent="error" class="mb-1"></html-renderer>
        </div>
      </div>
      <div v-else>
        <span><feather-icon icon="CheckCircleIcon" size="20" class="sc-icon"></feather-icon> Remessas estão em conformidade</span>
      </div>
    </div>
    <div v-else>
      <div class="d-flex justify-content-center flex-column align-items-center">
        <feather-icon icon="AlertTriangleIcon" size="50" class="wr-icon mb-2"></feather-icon>
        <h3 class="mb-2">Carregando informações do relatório de Lastros</h3><br>
        <img class="loadingImg" src="@/assets/images/loading.gif" height="60" width="60">
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router'
import http from '/src/services/http'
import { BCard } from 'bootstrap-vue'
import vSelect from 'vue-select'
import HtmlRenderer from '../../../common/components/HtmlRenderer.vue';

export default {
  name: 'ReportsIndex',
  components: {
    BCard,
    vSelect,
    HtmlRenderer,
  },
  data() {
    return {
      showModalEspera: false,
      errors: [],
    }
  },
  mounted() {
    this.getRelatorioLastro()
  },
  methods: {
    async getRelatorioLastro() {
      this.showModalEspera = true
      const { data } = await http.get('/recebiveis/relatorios/lastro')
      if(data.errors.length !== 0) {
        this.errors = data.errors
        this.dealWithErrors()
      }
      this.showModalEspera = false
    },
    dealWithErrors() {
      this.errors.forEach((error, index) => {
        if(error.includes('remessa')) {
          let id = error.split('remessa ').pop()
          this.errors[index] = error.replace(`remessa ${id}`, `<a href='/admin/concessoes/${id}' class='remessa-link'>remessa ${id}</a>`)
        }
      });
    },
  },
}
</script>

<style lang="scss">

.wr-icon {
  margin-bottom: 3px;
  margin-right: 12px;
  color: #F9A900;
}

.sc-icon {
  margin-bottom: 3px;
  margin-right: 12px;
  color: #237F52;
}

.v-select {
  width: 260px;
  background: white;
  margin-bottom: 28px;
  margin-left: auto;
  margin-top: -55px;
}

.remessa-link {
  color: #005387 !important;
}

.modalEspera {
  position: absolute;
  top: 30%;
  left: 35%;
  z-index: 9999;
  height: 10px;
}

.blur {
  width: 100%;
  height: 100%;
  z-index: 9998;
  backdrop-filter: blur(3px);
  position: absolute;
}
</style>
