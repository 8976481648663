<template>
  <div>
    <div>
      <div class="mt-5 d-flex flex-end">
        <v-select v-if="!!assignors"
          v-model="selectedAssignor"
          :options="assignors"
          :clearable="true"
          multiple
          placeholder="Cedente"
          class="assignor-selector d-flex flex-column mr-1"
        >
        </v-select>
      </div>
      <div v-if="!showLoadScreen">
        <b-table 
          ref="reportDataTableConcentracaoSacado" 
          class="border" 
          :items="reportData" 
          :fields="reportDataFields"
        >
        </b-table>
        <b-row class="mt-2" v-if="!!reportData">
          <b-col cols="3"></b-col>
          <b-col cols="6" class="d-flex justify-content-center">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              aria-controls="my-table"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        
          <b-col cols="3" class="d-flex align-items-center justify-content-end">
            <span class="mb-0 mr-1">Itens por página</span>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-flex m-0"
              style="width: 100px!important;"
            />
          </b-col>
        </b-row>
      </b-row>
      </div>
      <div v-else>
        <div class="d-flex justify-content-center flex-column align-items-center">
          <feather-icon icon="AlertTriangleIcon" size="50" class="wr-icon mb-2"></feather-icon>
          <h3 class="mb-2">Carregando relatorio de concentração por sacado</h3><br>
          <img class="loadingImg" src="@/assets/images/loading.gif" height="60" width="60">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router'
import http from '/src/services/http'
import { 
  BCol,
  BRow,
  BTab,
  BTabs,
  BCard,
  BTbody,
  BTable,
  BBadge,
  BButton,
  BDropdown,
  VBTooltip,
  BFormGroup,
  BFormInput,
  BPagination,
  BIconSearch,
  BDropdownItem,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  moneyFormatDatatable,
} from '@core/comp-functions/data_visualization/datatable'

export default {
  components: {
    BCol,
    BRow,
    BTab,
    BTabs,
    BCard,
    BTbody,
    BTable,
    BBadge,
    BButton,
    BDropdown,
    VBTooltip,
    BFormGroup,
    BFormInput,
    BPagination,
    BIconSearch,
    BDropdownItem,
    BFormCheckbox,
    vSelect,
  },
  data() {
    return {
      selected: false,
      showLoadScreen: true,
      selectedAssignor: null,
      assignors: [],
      reportData: [],
      perPage: 10,
      perPageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      errors: [],
      reportDataFields: [
        {
          key: 'nome',
          label: 'Nome',
        },
        {
          key: 'base_cnpj',
          label: 'CNPJ Base',
        },
        {
          key: 'quantidade',
          label: 'Quantidade'
        },
        {
          key: 'valor',
          label: 'valor',
          formatter: moneyFormatDatatable
        },
      ]
    }
  },
  async mounted() {
    this.showLoadScreen = true
    await this.getAssignors()
    await this.getReport()
    this.showLoadScreen = false
  },
  methods: {
    async getReport() {
      const params = {
        ...this.params,
        per_page: this.perPage,
        page: this.currentPage,
      }

      const { data } = await http.get('/recebiveis/relatorios/concentracao_sacado',  { params: params })
      this.reportData = data.data

      this.perPage = data.per_page
      this.totalRows = data.total_records
      this.currentPage = data.current_page
    },
    async getAssignors() {
      const data = await this.$store.dispatch('admin/getAssignors')
      this.assignors = data.map((assignor) => ({
        value: assignor.id,
        label: assignor.nome,
      }))
    },
    dealWithErrors() {
      this.errors.forEach((error, index) => {
        if(error.includes('remessa')) {
          let id = error.split('remessa ').pop()
          this.errors[index] = error.replace(`remessa ${id}`, `<a href='/admin/concessoes/${id}' class='remessa-link'>remessa ${id}</a>`)
        }
      });
    },
  },
  computed: {
    params() {
      let params = {}

      if (this.selectedAssignor) params.cedente_id = this.selectedAssignor.map((a) => a.value)

      return params
    },
  },
  watch: {
    params(newValue, oldValue) {
      if (newValue !== oldValue) this.getReport()
    },
    currentPage(newValue, oldValue) {
      if (parseInt(newValue) !== parseInt(oldValue)) this.getReport()
    },
    perPage(newValue, oldValue) {
      if (parseInt(newValue) !== parseInt(oldValue)) this.getReport()
    },
  },
}
</script>

<style lang="scss">

.wr-icon {
  margin-bottom: 3px;
  margin-right: 12px;
  color: #F9A900;
}

.sc-icon {
  margin-bottom: 3px;
  margin-right: 12px;
  color: #237F52;
}

.v-select {
  width: 260px;
  background: white;
  margin-bottom: 28px;
  margin-left: auto;
  margin-top: -55px;
}

.remessa-link {
  color: #005387 !important;
}

.modalEspera {
  position: absolute;
  top: 30%;
  left: 35%;
  z-index: 9999;
  height: 10px;
}

.blur {
  width: 100%;
  height: 100%;
  z-index: 9998;
  backdrop-filter: blur(3px);
  position: absolute;
}
</style>
