<template>
  <div>
    <h2>Relatório de prazo médio ponderado</h2>
    <div class="d-flex justify-content-end" style="gap: 10px">
      <flat-pickr
        v-model="dataReferencia"
        class="form-control bg-white date-selector "
        :config="dateFilterConfig"
        placeholder="Data Referência"
      />
      <button @click="getRelatorio" class="btn btn-secondary">Filtrar</button>
    </div>
    <div v-if="!showModalEspera && media != null" class="mt-3">
      <div class="d-flex justify-content-center flex-column align-items-center">
        <h4 class="mb-1">Média</h4>
        <h2 class="mb-2">{{ parseFloat((media || 0 )).toFixed(2) }} dias</h2>
      </div>
    </div>
    <div v-else-if="showModalEspera">
      <div class="d-flex justify-content-center flex-column align-items-center">
        <feather-icon icon="AlertTriangleIcon" size="50" class="wr-icon mb-2"></feather-icon>
        <h3 class="mb-2">Calculando média...</h3><br>
        <img class="loadingImg" src="@/assets/images/loading.gif" height="60" width="60">
      </div>
    </div>
  </div>
</template>
<script>
import http from '/src/services/http'
import { BCard } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import { formatDate } from '@core/utils/filter'


export default {
  name: 'ReportsIndex',
  components: {
    BCard,
    vSelect,
    flatPickr
  },
  data() {
    return {
      showModalEspera: false,
      errors: [],
      media: null,
      dateFilterConfig: {
        locale: Portuguese,
        dateFormat: 'd/m/Y',
      },
      dataReferencia: formatDate(new Date())
    }
  },
  mounted() {

  },
  methods: {
    async getRelatorio() {
      this.showModalEspera = true
      this.showModalTermo = true
      const { data } = await http.get(`/recebiveis/relatorios/prazo_medio_ponderado_titulos_agrupados`, {
        responseType: 'application/json',
        headers: { accept: 'application/json' },
        params: {
          reference_date: this.dataReferencia,
        },
      })
      this.media = data.media
      this.showModalEspera = false
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.v-select {
  width: 260px;
  background: white;
  margin-bottom: 28px;
  margin-left: auto;
  margin-top: -55px;
}
.assignor-selector {
  min-width: 240px;
}
.date-selector {
  max-width: 240px;
}
</style>
